.notifications {
    text-decoration: none;
    color: black;
    font-size: 0.5rem;
    padding: 0 20px;
    cursor: pointer;
}

.notificationsContainer > p {
    padding: none;
    margin: none;

}

.notificationsContainer {
    cursor: pointer;
    max-height: 70vh;
    overflow-y: scroll;
}

.notificationItem {
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e9e9e9;
}

.notificationItem:hover {
    background-color: #e9e9e9;
}

.notificationInformationContainer{
    display: grid;
    grid-template-columns: 25% 25% 50%;
}

.notificationMessageSection {
    display: grid; 
    grid-template-columns: 25% 75%;
    font-size: 1.2rem;
    font-weight: bolder;
    color: black;
}

.notificationFromSection {
    grid-column: 2;
}

.notificationDateSection {
    grid-column: 3;
}

.notificationFromSection, .notificationDateSection {
    display: inline-block;
    font-weight: bold;
    font-size: 1rem;
    text-align: left;
}

.notificationIcon {
    width: 50%;
    height: 50%;
    justify-self: center;
    align-self: center;
}

.popup-arrow {
    display: none;
}

.clearNotifBtn {
    margin-top: 1rem;
    width: 10em !important;
}

 @media screen and (max-width: 850px) {
    .navTitle {
        display: inline-block;
        margin-top: .5rem;
        margin-bottom: .5rem;
        margin-left: 1rem;
        font-size: 1rem;
    
    }
}