@import './adminPanelVariables';
@import '../globalmixins';

.swal-button-container {
  @include flexAlign(center, center);
}

.swal-button {
    text-align: center;
    width: 50%;
    height: 100%;
}

.postReportsPagination{
  @include flexAlign(center, center);
  @include flexDirection(row);
}

.backButton{
  @include flexDirection(row);
}

.noReportSelected {
  @include flexAlign(center, center);
}

.postReports{
  @include setSize($max,85vh);
  @include flexDirection(column);
  z-index: 0;
  background-color: $bg-lightgrey;
  position: fixed;
  right: -100%;
  transition: right 0.25s ease-in-out;
  overflow-y: auto;

  /* desktop view */
  @media screen and (min-width: $min-desktop-width) {
    grid-column: 2;
    width: 70%;
    transition: none;
    display : block;
    right : 0%;
  }

  @media screen and (min-width: $large-desktop-width) {
    @include flexDirection(row);
  }
  
    .emptyMessageBox {
      display: none;
  
      .sendMessageIcon {
        border: 2px solid $icon-color-grey;
        border-radius: 50px;
  
        h1 {
          font-size: 5rem;
          margin-left: 5px;
          margin-right: auto;
          color: $icon-color-grey;
        }
      }
    }
  
    &.active {
        right: 0%;
    }
  
    header {
      background: #ffffff;
      border-bottom: 1px solid #ccc;

      @media screen and (min-width: $min-desktop-width) {
        grid-column: 1;
        column-span: 2;
        align-items: center;
        border-bottom: none;
      }
  
      .backButton {
        text-align: center;
        font-size: 2rem;
  
        /* desktop view */
        @media screen and (min-width: $min-desktop-width) {
          display: none;
        }
      }
    }

    section {
      box-shadow: $default-box-shadow;
      border-radius: $default-border-radius;
    }
  }

  #prevButton, #nextButton{
    padding: 10px 15px;
    margin: 0 15px;
    width: fit-content;
    height: fit-content;
    p {
      margin-bottom: 0;
    }
  }

  .postReportsDetails{
    margin: 1rem;
    padding: 1rem;
    background-color: $bg-white;

    @media screen and (min-width: $large-desktop-width) {
      width: 40vw;
    }
  }
  
  .postReportsDetailsInfo{
    @include setMarginVertical(3rem, 3rem);
    p{
        font-size: $font-size-desktop;
    }

    @media screen and (max-width: $max-mobile-width) {
       P {
        font-size: $font-size-default;
       }
    }
    
  }

  .postReportsActions{
    @include flexAlign(center, null);
    @include flexDirection(row);
    margin-bottom: 20px;

    button{
      margin: 0 10px;
    }
  }
  
  #denyReportBtn{
    background-color: #5a5a5a;
    color: white;
  }
  
  #acceptReportBtn{
    background-color: #740000;
    color: white;
  }

  .commentReported {
    @include flexDirection(column);
  }

  .postReported {
    @include flexAlign(center, null);

    margin: 1rem;
    background-color: $bg-white;

    @media screen and (min-width: $large-desktop-width) {
      width: 40vw;
    }

    .postCardContainer {
      // width: 100%;
      max-height: 100%; 
      overflow: auto; 
      @include flexAlign(center, null);

      @media screen and (max-width: $max-mobile-width) {
        width: 100%;
      }
    }

    .postCard {
      @include setMarginHorizontal(1rem, 1rem);
      @include setMarginVertical(1rem, 1rem);
      max-width: 90%;
    }

    .comment-container {
      @include setMarginHorizontal(1rem, 1rem);
      @include setMarginVertical(1rem, 1rem);
      max-height: 20vh;
    }

    p {
      text-align: center;
    }

  }

