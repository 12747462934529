.deleteComment {
    display:flex;
    margin-left: auto;
    margin-right: 0.5rem;
    margin-bottom: auto;
}

.deleteCommentBtn {
    background-color: transparent;
    color: #575d63;
    border: none;
    font-size : 1.33333333em;
}

.commentOptions {
    display: flex;
    flex-direction: row;
}