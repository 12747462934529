* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 65px;
    background-color: rgba(255, 255, 255, 0.982);
    padding: 0;
    z-index: 3;
    border-bottom: 1px solid rgb(210, 210, 210);
}

.nav-links {
    margin-top: -5px;
}

.navIcon {
    font-size: 1.9rem;
}
.nav-links,
.nav-links-mobile {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin-right: 110px;
}

.logo {
    height: 90px;
    margin-top: -20px;
    margin-left: 40px;
}

.home,
.logo-home,
.about,
.community,
.messaging,
.profileNavItem {
    text-decoration: none;
    color: black;
    font-size: 0.5rem;
    padding: 0 20px;
    cursor: pointer;
}



.messaging {
    position: relative;
}

.notification {
    color: red;
    font-size: 0.8rem;
}

.user {
    font-size: 1rem;
    color: black;
}
.user:hover {
    font-size: 1rem;
    color: black;
}

.imageDisplay {
    width: 28px;
    height: 28px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7px;
    display: block;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}


.userDisplayName {
    margin-top: 0;
     
}


.profile-image {
    display: none;
}
h1 {
    text-align: center;
    color: rgb(46, 46, 46);
    font-size: 25px;
}

li {
    font-size: 0.85rem;
    color: rgb(46, 46, 46);
    position: relative;
    margin-top: -10px;
    opacity: 0.9;
    text-align: center;
}

li {
    opacity: 1;
}

.listItem::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background: rgb(132, 132, 132);
    transition:  all .45s
}

li:hover::before {
    width: 100%;

}

.mobile-menu-icon {
    margin-right: auto;
    display: none;
    
}

.popup-content {
    background-color: white;
    padding: 10px;
    border-radius: 3px;
    font-family: 'Lato', sans-serif;
    margin-top: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.imageDisplayPopUp {
    width: 70px;
    height: 70px;
   
}

.profileInfoPopUp {
    display: flex;
    justify-content: center;
    align-items: center;
}
.profileInfoPopUp > h1 {
    margin-left: 5px;
}

.popupName {
    font-size: 2rem;
}

.viewProfileBtn{
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100%;
    margin: 10px 0 3px 0;
  }
  
  .viewProfileBtn:hover{
    background-color: #f7fafa;
  }
  
  .viewProfileBtn:focus{
    border-color: #209d50;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
  }

  .signInPop {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100%;
    margin: 10px 0 3px 0;
}

.signInPop:hover {
    background-color: #f7fafa;
  }
  
  .signInPop:focus {
    border-color: #209d50;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
  }


.signIn_prompt {
    color: black;
}  

.signInTxt {
    font-size: 1rem;
    color: black;
}

.signoutNav {
    text-align: center;
    margin-top: 10px;
    color: #0f1111;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1rem;
}

.signoutNav:hover {
    color: #0f1111;
    font-weight: bold;
}

/* Media queries */
@media screen and (max-width: 1200px) {

    .navbar {

    }
    
    
    #nav-search {
        margin-right: 200px;
    }

    #search-bar-input {
        width: 30vw;
        max-width: 300px;
    }
    
    .nav-links {
        display: none;
    }
    
    .nav-links-mobile {
        position: absolute;
        display: flex;
        flex-direction: column;
        list-style: none;
        background-color: rgba(255, 255, 255, 0.982);
        left: 0;
        top: 65px;
        transition: all 0.5s ease-out;
        width: 100%;
        z-index: 3;
        padding: 0;
        border-bottom: 1px solid rgba(214, 214, 214, 0.909);
    }

    .navIcon, .imageDisplay{ display: inline-block;}

    .imageDisplay { 
        margin-top: 0;
        width: 1.9rem;
        height: 1.9rem;
    }

    .navTitle, .userDisplayName {
        display: inline-block;
        margin-top: .5rem;
        margin-bottom: .5rem;
        margin-left: 1rem;
        font-size: 1rem;
    }

    .popup-content {
        margin-top: 5rem;
    }
    
    .popup-arrow {
        display: none;
    }

    .profileNavItem {
        margin-bottom: 1rem;
    }

    .userLogin {
        font-size: 1rem;
        color: black;
        width: 100%;
        transition: all 0.5s ease;
        cursor: pointer;
    }

    .logo-home,
    .user {
        color: black;
        transition: all 0.5s ease;
        cursor: pointer;
    }

    .loginTxt {
        display: inline-block;
        text-align: left;
        margin-top: .5rem;
        margin-bottom: .5rem;
        margin-left: 1rem;
        font-size: 1rem;
    }

    .mobile-menu-icon {
        display: block;
        position: absolute;
        font-size: 30px;
        color: black;
        background-color: transparent;
        border: none;
        outline: none;
        top: 15px;
        right: 25px;
    }

    .popup-content {
        margin-top: 3px;
    }

    .viewProfileBtn{
        margin-left: -33px;
    }

    .notification {
        position: absolute;
    }
}


@media screen and (max-width: 390px) {
    .nav-links-mobile {
        margin-top: -0.55px;
    }
}