/* .rdw-dropdown-selectedtext > span {
    font-size: .8rem;
    color: #000;
} */

span {
    font-size: 1rem;
    color: #000;
}

/* a {
    text-decoration: none !important;
    color: #000;
} */

/* .post-details {
    margin-top: 10px;
} */

.rdw-editor-wrapper {
    margin-top: 20px;
    border : 1px solid #ced4da;
    border-radius: .375rem;
}

.rdw-link-modal {
    height: 250px;
}

.public-DraftStyleDefault-block {
    margin: 10px;
}


.form-floating .modal-footer {
    border-top: none !important;
    padding-left: 0;
    padding-right: 0;
}

.modal-footer .btn-primary {
    margin-left: 0;
    margin-right: 0;
}