.userPostContainer {
    padding-top: 10px;
 }
 
 .userPostBtn {
     margin-left: 15px;
 }
 
 .postAuthorContainer {
     display: flex;
     align-items: center;
 }
 
 .authorImage {
     max-width: 100%;
     width: 55px;
     height: 55px;
     border-radius: 50px;
     border: 0.5px solid rgb(188, 188, 188);
 }
 
 .username {
     text-align: start;
     font-family: 'Inter', sans-serif;
     margin-left: 10px;
 }
 
 /* backdrop not working properly for editpost */
 .modal-backdrop {
     position: fixed;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 0;
     background-color: rgb(143, 143, 143);
 }
 .modal-backdrop.in {
     filter: alpha(opacity=50);
     opacity: .5;
 }
 
 .editPostTextArea {
     height:200px;
     margin-bottom: 20px;
 }
 
 .modalContent {
     height: 500px;
 }
 
 .popup-content {
     margin: auto;
     /* padding: 0px; */
 }
 
 .modifyPost {
     position: absolute;
     right: 15px;
     top: 5px;
 
 }
 
 .modifyPost > h1 {
     font-size: 1.3rem;
     color: grey;
 }
 
 .editPost,
 .deletePost {
     display: flex;
     align-items: center;
     padding: 0 10px;
     cursor: pointer;
 }
 
 .editIcon,
 .deleteIcon {
     color: rgb(96, 96, 96);
     margin-right: 10px;
 }
 
 .modifyText {
     font-size: 1rem;
     margin-top: 5px;
 }
 
 
 .editPost:hover,
 .deletePost:hover {
     background-color: rgb(228, 228, 228);
 }
 
 .editPhotoAttachment {
     display: flex;
 }
 
 .editPhotoAttachment > img {
     width: 100%;
 }
 
 .editAttachmentPreviewClose {
     margin-left: -30px;
     margin-top: 5px;
     cursor: pointer;
 }


 /* .alertMessages {
    font-family: 'Inter', sans-serif;
 } */

 .swal2-popup {
    font-family: 'Inter', sans-serif;
 }