.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.logo-loading {
    width: 220px;
    height: 220px;
    border-radius: 50%;

}

.progressbar {
    margin-top: -100px;
}