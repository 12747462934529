@import "./consistentVariables";
@import '../globalmixins';

@mixin maxWidthAndHeight {
    max-width: $max;
    max-height: $max;
}

@mixin autoMarginHorizontal {
    margin-left: auto;
    margin-right: auto;
}


@mixin flexWrap($wrap) {
    display: flex;
    flex-wrap: $wrap;
}

@mixin buttonTemplate($backgroundColor, $borderColor){
    @include setMarginHorizontal(0.5rem, 0.5rem);
    @include setBorder($borderColor);
    background-color: $backgroundColor;
    border-radius: $border-Radius-Default;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-size: $font-Size-Default;
    font-weight: 500;
    outline: none;
    outline: 0;
    padding: 1rem;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    z-index: 0;
}

@mixin setBorder ($color){
    border: 1px solid $color;
}


* {
box-sizing: border-box;
}

// .container{
//     margin: 0px;
//     min-height: $max;
//     max-width: $max;
// }

#content{
    @include maxWidthAndHeight;
    height: 100%;
}

.communityContainer{
    @include maxWidthAndHeight;
    height: 100%;
}

.blogContainer{
    @include maxWidthAndHeight;
    height: 100%;
    overflow-y: scroll;

    @media screen and (max-width: $min-desktop-width) {
        height: 20vh;
    }
        
}

.router-Container > div {
    @include maxWidthAndHeight;
}

.formContainer{
    @include maxWidthAndHeight;
    margin: 0px;
}

.profileContainer {
    // @include autoMarginHorizontal;
    // @include setMarginVertical(0, 100px);
    height: 100%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    // padding: 20px;
    // border-radius: $border-Radius-Default;
    font-family: $font-Quicksand;
    background-color: #ffffff;
}

.innerProfileDiv {
    @include setGridColumns(30%, 70%);
    @include setGridRows(2%, 98%);
    height: 100%;
}

.backEditBtn {
    position: absolute;
}
.userProfileContents {
    @include flexAlign(center, center);
    @include flexDirection(column);
    @include placeItemOnGrid(1 / span 2, 1);

    @media screen and (max-width: $min-desktop-width) {
        .userProfileContents {
            @include placeItemOnGrid(2, 1);
    
            .userImage {
                width: 100px;
                height: 100px;
                min-width: 150px;
                min-height: 150px;
            }
        }
    }

    .userImage {
        z-index: 1;
        width: 150px;
        height: 150px;
        min-width: 150px;
        min-height: 150px;
    }
}

.profileData {
    @include setMarginHorizontal(0.5rem, 0.5rem);
    z-index: 1;
    text-align: center;
}

.editProfileBtn {
    @include placeItemOnGrid(1, 1);
    @include flexAlign(flex-end, null);
    margin-right: 5%;
    // max-width: 50%;
    cursor: pointer;
}
.editProfileText {
    z-index: 1;
    font-size: $font-Size-Default;
}
.userInfo {
    @include flexAlign(center, center);
    @include flexDirection(column);
    z-index: 1;
    width: 100%;
    margin-bottom: 10px;
}

.otherUserPosts{
    @include placeItemOnGrid(1 / span 2, 2);
    background-color: $bg-lightgrey;
    overflow-y: scroll;
    height: 91.5vh;
}

.userInfo > h1 {
    text-align: start;
    font-family: $font-Poppins;
}

.userInfo > h5 {
    font-size: calc(2px + 1vw);
}
.profilePostSection {
    @include setMarginVertical(5%, 10%);
    @include flexAlign(space-evenly, null);

    /** more than one button */
    button {
        @include setMarginHorizontal(5%, 5%);
    }
}

/* edit profile */

.editBtnContainer {
    width: 95%;
    margin: 0 auto;
}
.profilePostSection > button,
.profilePostBtn,
.saveEditBtn {
    @include buttonTemplate($button, $buttonDown);
}
.saveEditBtnLoading {
    @include buttonTemplate($button, $buttonDown);
}
.cancelEditBtn {
    @include buttonTemplate($cancel, $cancel);

}

.imageProfileContainer {
    // display: flex;
    // width: 65%;

    margin-top: 20px;
    @include autoMarginHorizontal;

    img {
        width: 150px;
        height: 150px;
    }
}

.userImage {
    background-color: $stroke-grey;
    border-color: $stroke-grey;
    border-width: 20px;
    border-radius: 50%;
    margin: 10px;
}


.editProfileContainer {
    padding: 10px;
}

.resetPasswordContainer {
    margin: 0 10px;
}

.imageUploadContainer {
    width: 95%;
    margin: -10px auto 20px auto;
}

.hide {
    display: none;
}

.selectPhotoBtn {
display: inline-block;
padding: 4px 12px;
margin-bottom: 0;
font-size: $font-Size-Default;
line-height: 20px;
color: #333333;
text-align: center;
vertical-align: middle;
cursor: pointer;
@include setBorder(rgb(198, 198, 198));
box-shadow: rgba(0, 0, 0, .1) 0 1px 3px 0;
border-radius: 4px;
background-color: rgb(206, 206, 206);
}

.btn-large {
padding: 5px 19px;
font-size: $font-Size-Default;
border-radius: 4px;
}

.edit-bio {
display: block;
height: 200px;
width: 100%;
padding: .375rem .75rem;
font-size: $font-Size-Default;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-clip: padding-box;
@include setBorder(#ced4da);
appearance: none;
border-radius: .25rem;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.linkedIcon > a, .instaIcon > a, .twitterIcon > a {
@include setMarginHorizontal(20%, 20%);
@include setMarginVertical(15%, 15%);
}

.postCard-userPost{
@include setMarginVertical(0, 50px);
max-width: 100%;
}

.unfollowBtn {
background-color: $buttonInactive !important;
border: 1px solid $buttonInactive !important;
}

.input-group {
    z-index: 0;
}

.submitChangesBtn {
    color: $font-color-light;

    &:hover {
        color: $font-color-light;
    }
}

/* Media queries */
@media screen and (max-width: 990px) {

    // .container{
    //     margin: 0px;
    //     min-height: 100%;
    //     max-width: 100%;
    // }
    
    .imageProfileContainer > img
    {
        width: 100px;
        height: 100px;
    }


    .innerProfileDiv {
        display: grid;
        max-height: 100vh;
        grid-template-columns: 100%;
        grid-template-rows: 5% 50% 45%;
        overflow: hidden;
    }

    .editProfileBtn {
        @include placeItemOnGrid(1, 1);
    }
    
    .otherUserPosts{
        @include placeItemOnGrid(3, 1);
        max-height:50vh;
    }
}

.modal-dialog {
    margin-top: 5rem;
}



@media screen and (max-width: $min-desktop-width) {
    // .profileContainer {
    //     padding: 0;
    //     margin: 45px 0 45px;
    // }
 h1.profileData {
    font-size: $font-Size-L;
    text-overflow: ellipsis;
    display: inline;
 }
 h3.profileData {
    font-size: $font-Size-L;
 }
 .userInfo > h5 {
    font-size: $font-Size-Default;
 }

//  .timeline-footer > a {
//     padding: 0%;
//  }
 .profilePostSection {
    margin: 10px 0;
 }
 .profilePostSection > button {
    display: block;
    padding: 2vw 5vw;
    font-size: $font-Size-Default;
 }
 .blogContainer > .container {
    padding: 0%;
 }
}
@media screen and (max-width: 452px){
    .linkedIcon > a, .instaIcon > a, .twitterIcon > a {
        @include setMarginHorizontal(7.5%, 7.5%);
        @include setMarginVertical(0, 0);
      }
}

@media screen and (max-width: 375px) {
    .editBtnContainer {
        @include flexWrap(wrap);
        justify-content: center;
    }
    .cancelEditBtn {
        margin-left: .5rem;
        margin-top: 10px;
    }
}

