@import './messagingvariables';
@import '../globalmixins';

@mixin nospace {
  margin: 0;
  padding: 0;
}

@mixin borderBottom($color) {
  border-bottom: 1px solid $color;
}

@mixin gifToggleTemplate($bg-color) {
  background-color: $bg-color !important;
  color: #D9D9D9 !important;
  padding: 4px 8px !important;
  border-radius: 5px !important;
}

//modified bootstrap button
@mixin buttonStyle($width, $border-radius) {
  width: $width;
  padding: $padding-small;
  font-size: $font-size-default;
  color: $font-color-light;
  background: $button-color-default;
  border-radius: $border-radius;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &:hover {
      background: $button-color-alt;
  }

}

/* MessageList (left side on desktop) */

.messageContainer {
  @include setSize($max, 91.25vh);
  @include flexDirection(column);
  min-height: 10em;

  /* desktop view */
  @media screen and (min-width: $min-desktop-width) {
    @include setGridColumns(30%, 70%);
    grid-template-rows: 100%;
  }
  
  .messageListContainer {
    @include setSize($max, 90vh);     /* 90vh -> set a fixed height for the container */
    @include flexDirection(column);
    flex: 1;
    width: $max;
    /* set a fixed height for the container */
    height: 100%;
  }
  
  .messageListItems {
    /* set the height to slightly less than the container height */
    height: 90%;
    @include nospace;
    list-style: none;
    overflow-y: scroll;

  }
  
  .messageListItem {
    @include setSize($max, 10%);
    @include flexAlign(null, center);
    border-top: 1px solid #ccc;
    padding: $padding-default;
    font-size: $font-size-default;
    background: $background-default;
  
    /* desktop view */
    @media only screen and (min-width: $min-desktop-width) {
      font-size: $font-size-desktop;
    }
  
    &.selected {
      background: $highlight-color-lightgray;
    }
  
    &:hover {
      background: $highlight-color-lightgray;
    }
  }
}

.search-bar {
  @include flexAlign(null, center);
  background: $background-default;
  border-radius: 12px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  margin: 2%;
  margin-bottom: 2.5%;

  input[type="text"] {
    flex: 1;
    border: none;
    padding: 8px;
    font-size: $font-size-default;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  button[type="submit"] {
    border: none;
    background-color: transparent;
    padding: 8px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    svg {
      font-size: $font-size-desktop;
    }
  }
}

.pageSwitch {
  @include setGridColumns(repeat(3, 1fr), null);
  width: 100vw;

  /* desktop view */
  @media screen and (min-width: $min-desktop-width) { 
    width: $max;
  }

  .socialBtn {
    @include buttonStyle(100%, 0);
    border-radius: none;
  }

  .selected {
    background-color: $button-color-alt;
  }
}

/* SelectedUserDetails (right side on desktop) */

.selectedUserContainer {
  @include setSize($max, $max);
  @include flexDirection(column);
  z-index: 0;
  background-color: $background-default;
  position: fixed;
  right: -100%;
  transition: right 0.25s ease-in-out;

  /* desktop view */
  @media screen and (min-width: $min-desktop-width) {
    grid-column: 2;
    width: 70%;
    transition: none;
    display : block;
    right : 0%;
  }

  .emptyMessageBox {
    display: none;

    @media screen and (min-width: $min-desktop-width) {
      @include flexAlign(center, center);
      @include flexDirection(column);
      text-align: center;
      margin-top: 150px;
    }

    .sendMessageIcon {
      @include setSize(100px, 100px);
      @include flexAlign(null, center);
      border: 2px solid $icon-color-grey;
      border-radius: 50px;

      h1 {
        @include setMarginHorizontal(5px, auto);
        font-size: 5rem;
        color: $icon-color-grey;
      }
    }
  }

  &.active {
      right: 0%;
  }

  header {
    @include setGridColumns(10%, 90%);
    @include borderBottom(#ccc);
    font-size: $font-size-default;
    background: $background-default;
    width: 100%;

    @media screen and (min-width: $min-desktop-width) {
      grid-column: 1;
      column-span: 2;
      align-items: center;
      border-bottom: none;
    }

    @media screen and (min-width: $medium-desktop-width) {
      column-span: 2;
    }

    .backButton {
      @include flexAlign(center, center);
      text-align: center;
      font-size: 2rem;

      /* desktop view */
      @media screen and (min-width: $min-desktop-width) {
        column-span: 2;
        display: none;
      }
    }
  }

  .privateUserContainer {
    @include flexAlign(center, center);
    @include flexDirection(column);
    @include setMarginVertical(1rem, 1rem);
    @include setMarginHorizontal(1rem, 1rem);
    box-shadow: $default-box-shadow;
    border-radius: $default-border-radius;
    background-color: #eee;

    h5 {
      @include setMarginVertical(1rem, 1rem);
      font-size: $font-size-desktop;
    }

    .privBtn {
      @include buttonStyle(null, $default-border-radius);
      @include setMarginVertical(1rem, 1rem);
    }
  
  }
}

/* chat section */

#chatSection {
  @include setGridRows(92%, 8%);
  position: relative;
  height: $max;
  // border: 1px solid black;
  grid-column: 1 / -1;
}

.messages {
  grid-row: 1;
  height: $max;
  overflow-y: scroll;
  margin: 0 10px;
}

  // Submit message form
.submitMessageForm {
  @include flexAlign(null, center);
  @include flexDirection(column);
}

.chatForm {
  @include flexAlign(space-between, center);
  width: 100vw;
  grid-row: 2;
  bottom: 0; /* Stick the form to the bottom of the screen */
  position: fixed;
  padding: 12px;
  background-color: #FAFAFA;
  border-top: 1px solid #E6E6E6;

  @media screen and (min-width: $min-desktop-width) {
    width: 70%;
  }

  input {
    flex: 1; /* Make the input fields take up all available space */
    padding: 10px;
    border: none;
    border-radius: $default-border-radius;
    font-size: 16px;
  }
  
  .chatMessageInput {
    flex: 1;
    padding: 10px 12px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    background-color: #F5F5F5;
    color: #262626;
    
    &::placeholder {
      color: #8E8E8E;
    }
  }
  
  button {
    @include flexAlign(center, center);
    margin-left: 12px;
    padding: 8px;
    border: none;
    border-radius: 50%;
    background-color: #F5F5F5;
    color: #8E8E8E;
    font-size: 16px;
    cursor: pointer;
    
    &:hover {
      background-color: #E6E6E6;
    }
    
    &:active {
      background-color: #D9D9D9;
    }
  }
  
  .chatSubmitBtn {
    padding: 1.5%;
    margin-left: 1.5%;
    border-radius: 50%;
    background-color: $button-color-default;
    color: $font-color-light;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
    
    &:hover {
      background-color: $button-color-alt;
    }
    
    &:active {
      background-color: $button-color-alt;
    }
  }

}

.message_wrapper {
  @include flexAlign(null, center);
  margin-bottom: 10px;
  
  &.own {
    @include flexDirection(row-reverse);
    
    .message_info {
      @include setMarginHorizontal(-8px, 0);
    }
    
    .me_photo {
      @include setMarginHorizontal(-8px, 0);
    }
    
    .me {
      @include setMarginHorizontal(0, 60px);
      background-color: #DCF8C6;
      color: $font-color-default;
      border-radius: 20px;
      padding: 10px;
      max-width: 60%;
      word-wrap: break-word;
    }
    
    .media {
      @include setMaxSize(100%, 200px);
    }
    
    .me_timePosted {
      @include setMarginHorizontal(0, 8px);
    }
  }
  
  .message_info {
    @include setMarginHorizontal(0, 8px);
  }
  
  .friend_photo {
    @include setMarginHorizontal(0, 8px);

  }
  
  .friend {
    background-color: black;
    margin-right: 0;
    border-radius: 20px;
    padding: 10px;
    max-width: 60%;
    word-wrap: break-word;
  }
  
  .friend_timePosted {
    @include setMarginHorizontal(8px, 0);
  }
}

.gpr-main {
  @include setMaxSize(450px, 800px);
  @include setSize(60vw !important, 50vh !important);
  position: absolute !important;
  display: block;
  bottom: 125px;
  right: 1%;
  z-index: 9999;

  @media screen and (max-width: 451px) {
    bottom: 150px;
  }

  @media screen and (max-width: 408px) {
    bottom: 175px;
  }
}

.gifToggleBtn{
  @include gifToggleTemplate(#8E8E8E);
}

.gifToggleBtn:hover{
  @include gifToggleTemplate(#555555);
}

.epr-main {
  @include setMaxSize(450px, 800px);
  @include setSize(60vw !important, 50vh !important);
  position: absolute !important;
  display: block;
  bottom: 125px;
  right: 1%;
  z-index: 9999;

  @media screen and (max-width: 451px) {
    bottom: 150px;
  }

  @media screen and (max-width: 408px) {
    bottom: 175px;
  }
}

h3 {
  text-align: center;
}

.messageItemContainer {
  @include flexDirection(column);
  height: fit-content;
  cursor: default;
  margin-bottom: 180px;
  gap: 10px;
}

.messageItem {
  @include flexDirection(row-reverse);

  &:has(.friend) {
    @include flexDirection(row);
  }

}

/* workaround for :has() not being compatible with firefox */
.messageItem.hasFriend {
  @include flexDirection(row);
}

.messageTime {
  @include flexAlign(center, center);
  color : $font-color-grey;
  margin: 0 10px;
  font-size: 0.75rem;
  font-weight: 400;
  text-align: center;
  max-width: $max;
  word-wrap: break-word;

  @media screen and (min-width: $min-desktop-width) {
    font-size: 1rem;
  }
}

.messageItemContent {
  @include flexDirection(column);
  @include flexAlign(center, center);
  padding: 10px;
  border-radius: $large-border-radius;
  max-width: 75%;
  margin-bottom: 10px;
  text-align: left;

  p {
    @include nospace;
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    color: $font-color-light;

    @media screen and (min-width: $min-desktop-width) {
      font-size: 1.5rem;
    }
  }

  img {
    @include setSize($max, $max);
    @include setMaxSize(40rem, 40rem);
    background-color: transparent;
    border-radius: $default-border-radius;
  }

  .YouTubeLink {
    @include flexAlign(center, center);
    margin-top: 1rem;
    

    iframe {
      @include setMaxSize(15rem, 15rem);
      border-radius: $default-border-radius;

      @media screen and (min-width: $max-mobile-width) {
        @include setMaxSize(25rem, 25rem);
      }

      @media screen and (min-width: $min-desktop-width) {
        @include setMaxSize(30rem, 30rem);
      }

      @media screen and (min-width: $medium-desktop-width) {
        @include setMaxSize(40rem, 40rem);
      }
    }
  }
}

#profileImage {
  @include setSize(2.5rem, 2.5rem);
  @include setMarginVertical(auto, 10px);
  margin-right: 1%;
  border-radius: 50%;
}

.friend {
  background-color: lightgray;
  align-self: flex-start;

  p {
    color: black;
  }

  a {
    color: $font-color-default;

    &:hover {
      color: $font-color-grey;
    }
  }
}

.friend:not(:has(p)) {
  border: none;
}

.you {
  color: $font-color-light;
  background-color: transparent;
  align-self: flex-end;
  background-color: $button-color-default; // don't remove this or this would make text look weird on firefox

  /* not pretty but workaround for :has() not being compatible with firefox */
  .messageText, .messageLink {
    border-radius: $large-border-radius;
    padding: 0.25rem;
    a {
      color: $font-color-light;

      &:hover {
        color: $highlight-color-lightgray;
      }
    }
  }

  // &:has(p) {
  //   background-color: $button-color-default;
  // }

  p {
    color: $font-color-light;
  }
}

button.chatFormButton {
  background-color: #0088CC;
  border-radius: 16px;
  border: 0;
  padding: 0.75%;
  margin: 0;
  color: white;
  margin-left: 1%;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #006699;
  }
  
  &:active {
    background-color: #005580;
  }

}

#imageButton, #emojiButton, .chatSubmitBtn {
  padding: calc(1% + 2px);

  &:active {
    background-color: lightblue;
  }
}

#gifButton {
  padding: calc(0.8%);
}