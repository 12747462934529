// <header className="communityHeader">
// <div className="headerCard">
//   <h1>Community name goes here</h1>
//   <div>Community bio goes here</div>
//   <div>Amount of members goes here</div>
//   <div>Name of the creator goes hListItemere</div>
// </div>
// </header>

@import '../social/messagingvariables';
@import '../globalmixins';
// @import "../social/messaging.scss";

@mixin banner {
    @include flexAlign(center, flex-start);
    @include flexDirection(column);
    width: 100vw;
    margin-bottom: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .headerCard {
        @include setSize(80%, 300px);
        @include flexAlign(center, flex-start);
        @include flexDirection(column);
        max-width: 700px;
        margin: 1rem 0;
        margin-left: 30px;
        padding: 1% 0;

        h1 {
            font-size: 350%;
            color: white;
            text-align: left;
            filter: drop-shadow(3px 3px 3px #000000);
        }
    
        div {
            font-size: 125%;
            text-align: left;
            color: rgb(255, 255, 255);
            filter: drop-shadow(2px 2px 1px #000000);
        }
    }

}

@mixin buttonStyle {
  width: 50%;
  padding: $padding-small;
  font-size: $font-size-default;
  color: $font-color-light;
  background: $button-color-default;
  border-radius: $default-border-radius;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &:hover {
      background: $button-color-alt;
      color: $font-color-light;
  }

  &:active {
    box-shadow: none;
    color: $font-color-light;
    background-color: $button-color-default;
  }

  &:focus  {
    box-shadow: none;
  }

}

.adminHeader {
    @include banner;
}

.communityHeader {
    @include banner;
    background-attachment: fixed;
    background-color: #FFFFFF;
}

.communitiesContainer {
    @include flexDirection(column);
    height: 140rem;

    .card-actions {
        position: absolute;
        top: 10px;
        right: 10px;
      }

    .delete-button {
        margin-left: 1rem;
        margin-top: 0px !important;
        background: red !important;

        &:hover {
            background: darkred !important;
        }
    }

    h1 {
        font-size: 3rem;
        text-align: center;
        margin-bottom: 2rem;
    }

    // <div className="search-bar">
    // <button type="submit">
    //     <FaSearch />
    // </button>
    // <input onChange={search} type="text" placeholder="Search" />
    // </div>
}

.communitiesDetails {
    @include flexAlign(center, center);
    @include flexDirection(column);
    margin-top: 2rem;
}

$communtiesMaxWidth: 1250px;

.communitiesList {

    padding: 2rem 5%;
    
    .search-bar {
        margin: auto; /* Use 'auto' to center horizontally */
        width: 100%;
        max-width: $communtiesMaxWidth;
        margin-bottom: 2em;
    }
}

.no-posts{
    @include setMarginVertical(50px, 50px);

    p{
        text-align: center;
        font-size: 200%;
    }

    .fa-folder-open{
        display: block;
        text-align: center;
        justify-self: center;
        align-self: center;
        font-size: 1000%;
    }
}

.communities {
    @include setMaxSize($communtiesMaxWidth, 700px);
    @include setGridColumns(repeat(3, 1fr), null);
    margin: auto; /* Use 'auto' to center horizontally */
    display: grid;
    place-items: center; /* Center items both horizontally and vertically */
    gap: 2.5%;
    overflow-y: scroll;
}

.card {
    @include setSize(100%, 100%);
    background: white;
    border-radius: $large-border-radius;
    box-shadow: $default-box-shadow;

    // &:hover:not(button) {
    //     // change the brightness of the card
    //     filter: brightness(70%);
    //     cursor: pointer;
    // }
}

.card-image {
    @include setSize(100%, 50%);
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.card-content {
    @include flexAlign(space-between, center);
    @include flexDirection(column);
    padding: 1rem;
    height: 50%;
}

.card-content h3 {
    font-size: 1.5rem;
    text-align: center;
}

.card-content p {
    font-size: 1rem;
    text-align: center;
}

.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
    background-color: $button-color-alt;
    color: $font-color-light;
}

.communityModalImage {
    @include setSize(100%, 100%);
    object-fit: cover;
    border-radius: 1rem 1rem 0 0;
}

.communityModalBio {
    margin-top: 1rem;
    font-size: 1.25rem;
    text-align: center;
}

.bs-community-admin-modal {
    padding: 0;
}

.modal-body {
    margin: 0;
    
    header {
        color: white;
        width: 100%;
    }
}

.adminCommunities {
    background: #eee;
}

.bs-community-footer-modal {
    --bs-modal-footer-gap: 0;
}

.joinCommunityBtn {
    @include setMarginHorizontal(1rem, 1rem);
}

.communityModalBtn {
    @include setMarginVertical(1rem, 1rem);
}

.communityAdminContents {
    @include setSize(100%, 100%);
}

.modalBtn {
    @include buttonStyle;
    @include setMarginVertical(1rem, 1rem);
    @include setMarginHorizontal(auto, auto);
}

.modalPageSwitch {
    @include placeItemOnGrid(1, 1 / span 2);

    .adminBtn {
        border-radius: 0;
        margin: 0;
        width: 50%;
    }
}
  
@media screen and (max-width: 768px) {
    .communities {
        @include setGridColumns(repeat(2, 1fr), null);
        @include setGridRows(repeat(3, 1fr), null);
    }
}

@media screen and (max-width: 450px) {
    .communities {
        @include setGridColumns(repeat(1, 1fr), null);
        @include setGridRows(repeat(6, 1fr), null);
    }
}

