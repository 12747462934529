.modal-body {
    padding: 5%;
    margin: 0%;
}

#postContentBox {
    height: 200px;
    margin-bottom: 15px;
}

.postImageContainer {
    border: 1px solid black;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    width: 40%
}

.postImages {
    width: 50%;
    margin-right: 10px;
}

#uploadFiles {
    width: 78%;
    float:left;
}

#uploadButton {
    width: 20%;
    float: right;
    border-left: 0px;
}

.joinContent, .postSection{
    /* height: 30vh; */
    padding: 20px;
    font-family: 'Inter', sans-serif;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 350px;
    background-color: #fff;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    border: 2px rgb(151, 151, 151);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
.joinPrompt {
    color: black;
    font-size: 1.1rem;
    margin-top: 15px;
}

.registeredUserButton {
    margin: 5px;
}

/* Media queries */

@media screen and (min-width: 568px) {
    .joinContent, .postSection {
        width: 500px;
    }
    
}


@media screen and (min-width: 768px) {
    .joinContent, .postSection {
        width: 600px;
    }
    
}

@media screen and (min-width: 860px) {
    .joinContent, .postSection {
        width: 700px;
    }
    
}
