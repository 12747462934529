$max: 100%;
$bg-lightgrey : #eee;
$min-desktop-width: 990px;
$font-Quicksand: 'Quicksand', sans-serif;
$font-Poppins: 'Poppins', sans-serif;
$font-Lato: 'Lato', sans-serif;
$font-Size-Default: 1rem;
$font-Size-L: 1.5rem;
$font-Size-XL: 2rem;
$font-color-light: #fff;
$padding-Default: 1rem;
$border-Radius-Default: .5rem;
$stroke-grey: #858585;
$button: #0b9444;
$buttonDown: #13aa52;
$buttonInactive: #505050;
$cancel: #cf142b;