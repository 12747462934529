.user_wrapper {
  margin: 0;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  justify-self: center;
}

.user_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected_user {
  background-color: rgba(244, 240, 240, 0.867);
}

.user_detail {
  display: flex;
  align-items: center;
}


.truncate {
  font-size: 0.8rem;
  color: rgb(114, 114, 114);
  margin: -5px 10px 0 10px;
  font-family: 'Lato', sans-serif;
  font-style: italic;
  
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
 
}

.truncate strong {
  margin-right: 10px;
}

.unread {
  margin-left: 20px;
  width: 10px;
  height: 10px;
  background: #2086fbec;
  color: white;
  padding: 2px 4px;
  border-radius: 30px;
  font-size: 0.8rem;
}


.user_detail h4 {
  margin-left: 10px;
  font-size: 1rem;
}

#user_data_displayname{
  margin-top: 5%;
  margin-bottom: 5%;
}

.user_data {
  display: flex;
  flex-direction: column;
  width: 200px;
}

.userPhoto {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid rgba(183, 183, 183, 0.716);
}

.user_status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-top: 27px;
  margin-left: -10px;
}

.online {
  background: #34eb52;
}

.offline {
  background: rgb(240, 240, 240);
  border: 1px solid grey;
}


/************ Media Queries **************/

@media screen and (max-width: 750px) {
  .selected_user {
    background-color: rgb(255, 255, 255);
  }

  /* .user_wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  } */

  .user_wrapper:active {
    background-color: rgb(237, 237, 237);

  }
}
