.bannerContainer {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-image: url(../imgs/homepage_image2.jpg);
    /* background-image: url(../imgs/homepage_image.png); */
    background-size: cover;
    background-position: center;
}

.bannerTagLine {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    box-sizing: content-box;
    line-height: 1.4;
}

.tagline1 {
    color: #ffff;
    text-align: center;
    font-size: 80px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    z-index: 1;

    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
        0px 8px 13px rgba(0, 0, 0, 0.1),
        0px 18px 23px rgba(0, 0, 0, 0.1);
}

.tagline2 {
    color: #ffff;
    text-align: center;
    font-size: 30px;
    font-family: 'Lato', sans-serif;

    letter-spacing: 1px;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4),
        0px 8px 13px rgba(0, 0, 0, 0.1),
        0px 18px 23px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.textAnimate > p{
    color: #ffffff;
}



.viewPostBtn {
    background-color: #10bf59;
    border: 1px solid #13aa52;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    outline: none;
    outline: 0;
    padding: 10px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    z-index: 0;
}

.viewPostBtn:hover {
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
    transform: translateY(-2px);
}

.buttontxt,
.buttontxt:hover {
    text-decoration: none;
    color: white;
}

.contentPhraseContainer {
    height: 50vh;
    display: flex;
    align-items: center;
    margin-top: -55px;
}

.contentPhraseContainer>h1 {
    font-size: 3.4rem;
    font-family: 'Inter', sans-serif;
    color: #000000;
}

.contentFeatureContainer {
    display: flex;
    justify-content: space-around;
    height: 60vh;
    margin-bottom: 200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.contentFeature {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    padding: 10px;
    margin: 5px;
}

.imageFeature {
    display: grid;
    grid-template-columns: auto;
    width: 350px;
}

.textFeature {
    margin-left: 20px;
    margin-right: 20px;
    height: 170px;
}

.contentFeature>h1 {
    font-family: 'Lato', sans-serif;
    color: #000000;
    font-size: 1.4rem;
    margin-top: 5px;
}

.textFeature>p {
    margin-top: 25px;
    /* font-family: 'Lato', sans-serif; */
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    color: #000000;
    text-align: justify;
}

.highlightedText {
    font-size: 2rem;
}

/******************* Media queries *********************/
@media screen and (min-width: 300px) {
    .contentFeature {
        width: 350px;
    }
    .imageFeature {
        width: 350px;
    }


}


@media (min-width: 768px) {
    .viewPostBtn {
        padding: 10px 30px;
    }

}

@media screen and (max-width: 780px) {


    .tagline1 {
        font-size: 60px;
        
    }

    .tagline2 {
        font-size: 20px;
    }

    .contentPhraseContainer {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .contentPhraseContainer>h1 {
        font-size: 2.7rem;
    }

    .contentFeatureContainer {
        flex-wrap: wrap;
    }


}

@media screen and (max-width: 880px) {
    .contentFeatureContainer {
        flex-wrap: wrap;
        height: 100%;
        margin-bottom: 100px;
    }

    .contentPhraseContainer {
        margin-top: 30px;
        margin-bottom: 40px;
        height: 30vh;
    }

}

@media screen and (max-width: 1300px) {
    .contentFeature {
        width: 350px;
        height: 60vh;
    }
    .imageFeature {
        width: 350px;
    }
    
}

@media screen and (max-width: 1100px) {
    .tagline1 {
        font-size: 3.5rem;
    }
    .contentFeatureContainer {
        margin-top: 0px;
    }

    .contentPhraseContainer {
        margin-top: 40px;
        margin-bottom: 40px;
        height: 30vh;
    }

    .contentPhraseContainer > h1 {
        font-size: 2.7rem;
    }
    
    .contentFeature {
        width: 280px;
        height: 60vh;
    }
    .imageFeature {
        width: 280px;
    }
    
}

@media screen and (max-height: 700px){
    .bannerContainer {
        height: 110vh;
    }


    .contentPhraseContainer {
        height: 55vh;
    }

    .contentFeature{
        height: 80vh;
    }
}

@media screen and (max-height: 450px) {
    .bannerContainer {
        height: 120vh;
    }

    .contentPhraseContainer {
        height: 70vh;
    }

    .contentFeature {
        height: 110vh;
    }
}



