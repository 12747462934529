/* Styling for community page, and also for post layout. didn't have time to convert it to scss */

.datePosted {
    font-family: 'lato', sans-serif;
    font-size: 0.8rem;
    margin: -5px 0 0 10px; 
    text-align: start;
}
.postContent {
    padding: 10px 25px 10px 25px;
    font-size: 1rem;
}
.postTitle {
    text-align: start;
    font-size: 1.3rem;
    font-family: 'Poppins', sans-serif;
    margin: 25px 25px 5px 25px;
}

li {
    text-align: start;
}

.blogContainer, .communityContainer {
    background-color: rgb(237, 237, 237);
}

.communityContainer {
    z-index: -2;
}
.postArrayContainer {
    padding: 0;
}
.postContainer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;
    padding: 0;
}

.postCard,
.postCard-userPost {
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    position: relative;
    padding-top: 20px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    width: 350px
}
.postCard-userPost {
    margin-top: 50px;
}
.makeStyles-container-1 > .makeStyles-iconContainer-3 {
    margin: auto;
    text-align: center;
}

.makeStyles-container-1 > .makeStyles-title-2{
    display: none;
}

.makeStyles-container-1 {
    margin: auto;
}


.jss1 > .jss3 {
    margin: auto;
    text-align: center;
}

.jss1 > .jss2{
    display: none;
}

.jss1 {
    margin: auto;
}


.timeline .timeline-body:before {
    content: '';
    display: block;
    position: absolute;
    border: 10px solid transparent;
    border-right-color: #fff;
    left: -20px;
    top: 20px
}

.timeline .timeline-body>div+div {
    margin-top: 15px
}

.timeline .timeline-body>div+div:last-child {
    margin-bottom: -20px;
    padding-bottom: 20px;
    border-radius: 0 0 6px 6px
}

.timeline-header {
    display: grid;
    grid-template-columns: 80% auto;
    grid-template-rows: auto auto;
    margin-top: -10px;
    line-height: 30px;
    padding-left: 10px;
}

.timeline-header .userimage {
    float: left;
    width: 34px;
    height: 34px;
    border-radius: 40px;
    overflow: hidden;
    margin: -2px 10px -2px 0
}

.timeline-header .username {
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
}
.commentUser {
    font-size:1.1rem;
    font-family: 'Poppins', sans-serif;
}
.commentContent {
    font-family: 'Inter', sans-serif;
    margin: 15px 15px 10px 15px;

    font-size: 1.1rem;
}
.comment-footer {
    text-align: left;
    font-size: 0.8rem;
    border-bottom: 1px solid #e2e7ec;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
}
.postAuthorContainer {
    display: flex;
    align-items: center;
}

.authorImage {
    width: 45px;
    height: 45px;
    border-radius: 50px;
    border: 0.5px solid rgb(188, 188, 188);
    margin-left: 10px;
}

.postAuthorImage {
    width: 45px;
    height: 45px;
    border-radius: 50px;
    border: 0.5px solid rgb(188, 188, 188);
    margin: 10px 0 0 10px;
}

.postOptions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.postOptions > button {
    margin-right: 10px;
    color: #575d63;
}

.editPostBtn, .deletePostBtn {
    background-color: #FFF;
    border: none;
    font-size: 1.33333333em;
}

.authorDetails {
    grid-row: 2;
    margin-top: 10px;
}
.username {
    text-align: start;
    font-family: 'Inter', sans-serif;
    margin-left: 10px;
}

.delete-comment-btn {
    float: right;
    margin-top: 15px;
    margin-right: 10px;
}

.timeline-header .username,
.timeline-header .username a {
    color: #2d353c
}

.timeline img {
    max-width: 100%;
    display: block
}

.timeline-content {
    letter-spacing: .25px;
    line-height: 18px;
    font-size: 13px;
    padding: 0;
}

.timeline-content:after,
.timeline-content:before {
    content: '';
    display: table;
    clear: both;
}



.timeline-title {
    margin-top: 0
}

.postImage > img {
    width: 100%;
    height: 100%;
}

.timeline-likes {
    color: #6d767f;
    font-weight: 600;
    font-size: 12px;
    padding-right: 15px;
}

.timeline-likes .stats-right {
    padding-top: 15px;
    float: right
}

.timeline-likes .stats-total {
    display: inline-block;
    line-height: 20px
}

.timeline-likes .stats-icon {
    float: left;
    margin-right: 5px;
    font-size: 9px
}

.timeline-likes .stats-icon+.stats-icon {
    margin-left: -2px
}

.timeline-likes .stats-text, .stats-text {
    line-height: 20px;
    font-size: .75rem;
    color: #575d63;
}

.timeline-likes .stats-text+.stats-text {
    margin-left: 15px
}

.timeline-comment-box {
    background: #f2f3f4;
    padding: 10px 15px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.timeline-comment-box .user {
    float: left;
    width: 34px;
    height: 34px;
    overflow: hidden;
    border-radius: 30px
}


.user > img {
    width: 40px;
    height: 40px;
}

.comment-text-area {
    margin-right: 10px;
}

.lead {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.4;
}

.text-danger, .text-red {
    color: #ff5b57!important;
}

.timeline-footer a {
    padding: 5px 2px 5px 15px;
    margin: 10px 0;
}

.timeline-footer {
    text-align: left;
    background: #fff;
    border-top: 1px solid #e2e7ec;
    border-bottom: 1px solid #e2e7ec;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
}

.timeline-footer a:not(.btn) {
    color: #575d63
}

.commentAuthorDetails {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.comment-username {
    font-size: 16px;
}

.comment-datePosted {
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    text-align: start;
    margin-left: 10px;
    font-style: italic;
}

.commentUser {
    font-family: 'Poppins', sans-serif;
    margin-left: 10px;
}
.comment-container {
    border-top-style: 1px solid grey;
    margin: 3px;
    padding: 5px;
    background-color: #ebebeb;
    border-radius: 10px;
}

.comment-likes {
    font-size: 0.8rem;
    color: #6d767f;
    display: flex;
    align-items: center;
    padding: 0;
    height: 30px;
}
.comment-stats-right {
    margin-left: auto;
    margin-right: 10px;
    order: 2;
}
.commentBtn {
    height: 40px;
}

.commentInput {
    height: 40px;
}

.create-comment-btn {
    justify-content: right;
    z-index: 1;
}

.view-comments-span,
.upvoteText {
    font-size: 0.85rem;
    color: #575d63;
}


.borderless-btn {
    background-color: transparent;
    border: none;
    color: #575d63;
}

.upvoteIcon {
    margin-right: 5px;
}
.hasVotedIcon {
   color: blue;
}


/* userPosts page */
.userBlogContainer {
    margin: 70px 0 80px 0;
}


.emptyPost {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'Inter', sans-serif;
}

.postIcon {
    width: 100px;
    height: 100px;
    border: 4px solid rgb(164, 164, 164);
    border-radius: 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 60px auto 20px auto;
    
}

.postIcon > h1 {
    font-size: 4rem;
    color: rgb(146, 146, 146);
}

.emptyPost > h2 {
    font-size: 2.5rem;
}

.createPostContent {
    margin: 20px 0 0 0;
    background-color: #10bf59;
    border: 1px solid #13aa52;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    outline: 0;
    padding: 10px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    z-index: -3;
}

.createPostContent:hover {
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
    transform: translateY(-2px);
}
.load-btn-div {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.modalEdit {
    font-family: 'Lato', sans-serif;
}

.modalPost {
    font-family: 'Lato', sans-serif;
}

.communityBtn, .communityBtn:hover, .communityBtn:active, .communityBtn:visited {
    background-color: #10bf59 !important;
    border: 1px solid #13aa52 !important;
    font-family: 'Lato', sans-serif;
    outline: none !important;
}

.communityBtn:hover, .communityBtn:active {
    background-color: #0f9e4b !important;
    border: 1px solid #12a04d !important;
    outline: none !important;
}

.communityBtn:focus {
    border: 1px solid #12a04d !important;
    box-shadow: none !important;
    outline: none !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #0f9e4b !important;
}

.shareText {
    text-decoration: none;
}
.communityPrompt {
    font-size: 1.1rem;
}




/******************** Media Queries ******************************/

@media screen and (max-width: 568px) {
    .postCard,
    .postCard-userPost {
        width: 100%;
    }
    
}


@media screen and (min-width: 568px) {
    .postCard,
    .postCard-userPost {
        width: 600px;
    }
    
}


@media screen and (min-width: 768px) {
    .postCard,
    .postCard-userPost {
        width: 600px;
    }
    
}

@media screen and (min-width: 860px) {
    .postCard,
    .postCard-userPost {
        width: 700px;
    }
    
}


@media screen and (max-width: 860px) {
    .view-comments-span,
    .shareText,
    .upvoteText {
        display: none;
    }

    .timeline-footer {
        padding-left: 5px;
    }
    
}




