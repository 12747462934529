.footerContainer {
    background-color: rgb(51, 51, 51);
    color: white;
    height: 35vh;
    width: 100%;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: auto;
    font-family: 'Lato', sans-serif;
    
}

.footerContentContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.signUpdateContainer > p {
    color: #fff;
}

.emailInput {
    height: 45px;
    width: 240px;
    padding: 10px;
    border-radius: 2px;
    border: none;
}


.submitBtn {
    background-color: #209d50;
    border: 1px solid #209d50;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    outline: 0;
    padding: 10px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    z-index: 0;
    margin: 10px;
  }

.blog {
    color: #fff;
}

.event {
    color: #fff;
}

.socialsContainer {
    text-align: center;
}  

.socialsContainer > p {
    color:#fff;
}

.socialmedia {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}


.instaIcon,
.twitterIcon,
.linkedIcon {
    height: 50px;
    background-color: transparent;
    width: 50px;
    border-radius: 50px;
    border: 2px solid white;
    margin: 5px;
    cursor: pointer;
}  

.socialIcons {
    font-size: 30px;
    margin-top: 8px;
    margin-bottom: auto;
    color: white;
}

.socialIconsProfile {
    font-size: 30px;
    margin-top: 8px;
    margin-bottom: auto;
    color: black;
}

a {
    text-decoration: none;
    color: #ffff;
}

.communityresources {
    text-align: center;
    padding-top: 10px;
}

.communityresources > a:hover {
    color: #fff;
}

.about {
    color: #fff;
    font-size: 1rem;
}

.event {
    margin-top: -15px;
    text-decoration: none;
}

.copyrightContainer {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;

    

    line-height: 40px;
    bottom:0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.copyrightContainer > p {
    font-size: 14px;
    color: #fff;
}


/* Media queries */


@media screen and (max-width: 1180px) {
    .footerContainer {
        height: 40vh;
    }
    

    .signUpdateContainer {
        width: 450px;
    }
}

@media screen and (max-width: 880px) {
    .footerContainer {
        height: 55vh;
    }
    
    .socialsContainer {
        margin-top: 40px;
    }  
    
}

@media screen and (max-width: 780px) {
    .communityresources {
        margin-top: 0px;
    }
    .socialsContainer {
        margin-top: 10px;
    }
    
}



@media screen and (max-width: 450px) {
    .footerContainer {
        padding: 25px 15px 15px 10px;
        height: 50vh;
    }
    .copyrightContainer {
        position: relative;
        margin-left: 0px;
        width: 100%;
    }

    .socialIcons{
        font-size: 1.4rem;
    }
    .instaIcon,
    .twitterIcon,
    .linkedIcon {
        height: 40px;
        width: 40px;
    }  
}

@media screen and (max-width: 380px) {
    .footerContainer {
        padding: 25px 15px 15px 10px;
        height: 60vh;
    }
    .copyrightContainer {
        position: relative;
        margin-left: 0px;
        width: 100%;
    }

    .socialIcons{
        font-size: 1.4rem;
    }
    .instaIcon,
    .twitterIcon,
    .linkedIcon {
        height: 40px;
        width: 40px;
    }  
}