.loginContainer {
    width: 500px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    margin-bottom: 100px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 20px;
    border-radius: 5px;
    font-family: 'Quicksand', sans-serif;
    background-color: #ffffff;
}
.loginText {
    margin-bottom: 30px;
}

.emailInputContainer, 
.passwordInputContainer,
.nameInputContainer {
    margin: 5px 10px 20px 10px;
}

.emailInputContainer > label, 
.passwordInputContainer > label,
.nameInputContainer {
    color: rgba(83, 83, 83, 0.768);
    margin-top: 5px;
}

.passwordTextContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    color: rgba(83, 83, 83, 0.768);
}

.forgotPassword {
    color: #113988;
    font-weight: 700;
    cursor: pointer;
}

.signInBtn {
    background-color: #209d50;
    border: 1px solid #209d50;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    outline: none;
    outline: 0;
    padding: 10px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    z-index: 0;
    margin-left: 10px;
    margin-right: 10px;
    width: 440px;
}

.form-control:focus {
    border-color: #209d50;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
} 

.otherSignInContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.otherSignInButtons {
    background-color: #FFFFFF;
    border: 1px solid #1b1b1b84;
    border-radius: 8px;
    box-sizing: border-box;
    color: #030303;
    font-family: 'Lato', sans-serif;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    outline: none;    
    position: relative;
    text-align: start;
    text-decoration: none;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    width: 300px;
    margin: 10px 10px 0px 10px;
    padding: 2px 0 2px 10px;
}

.otherSignInButtons:focus {
    background-color: #81e1a61f;
}

.line {
    width: 95%; 
    text-align: center; 
    border-bottom: 1px solid #1b1b1b84; 
    line-height: 0.1em;
    margin: 20px 10px 10px 10px; 
}

.line > span {
    background:#fff; 
    padding:0 10px;
    font-size: 15px; 
    font-family: 'Lato', sans-serif;
}

.signIcon,
.facebookSign,
.twitterSign {
    font-size: 35px;
}

.facebookSign {
    color: #4267B2;
}
.twitterSign {
    color: #1DA1F2;
}

.otherSignText {
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    margin-left: 40px;
}

.otherSignOptionContainer {
    color: #030303;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 40px 20px 0 10px;
    font-size: 0.9rem;
}

.registertext {
    color: #4267B2;
    font-weight: 700;
    font-size: 0.9rem;
    margin-left: 2px;
}

.noAccountText {
    font-size: 0.9rem;
}
.noAccountContainer {
    display: flex;
    font-size: 0.9rem;
}

.experienceInputContainer {
    margin: 10px 10px 20px 10px;
    text-align: center;
}

.experienceText {
    font-size: 1.3rem;
    font-weight: bold;
}

.errorText {
    color: rgb(250, 166, 166);
}

.resetFormContainer {
    margin-top: 100px;
    margin-bottom: 100px;
}


/********************* Media Queries ****************************/

@media screen and (max-width: 390px) {
    .loginContainer {
        width: 90%;
    }

    .signInBtn {
        width: 95%;
    }

    .otherSignInButtons {
        width: 95%;
    }
}

@media screen and (max-width: 600px) {
    .loginContainer {
        width: 90%;
    }

    .signInBtn {
        width: 95%;
    }

    .otherSignInButtons {
        width: 95%;
    }
}
