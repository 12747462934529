.attachmentIcon > svg {
    margin-right: 0.5rem;
}

.rdw-editor-main {
    min-height: 20vh;
}

.postPhotoAttachment {
    width: 40%;
    height: 10vh;
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: row;
    background-color: #e9e9e9ec;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 3px;
}

.postPhotoAttachment > img {
    width: 100px;
    height: 9vh;
    margin: 5px;
}

.postPhotoAttachment > h6 {
    font-size: 0.9rem;
    width: 60%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.closeAttachment {
    position: absolute;
    right: 0;
    margin: 5px;
    cursor: pointer;
}

.postContentData {
    text-align: start;
    margin-top: 0.5rem;
    /* font-family: 'Lato', sans-serif; */
    font-family: 'Inter', sans-serif;
    font-size: 0.9rem;
    line-height: 20px;
}
