.list-group {
    margin: 1em 0;
}

.list-group-item {
    background-color: white;
    cursor: pointer;
}

.list-group-item-header {
    cursor: default;
}

.list-group-item-header > p {
    font-size: 100%;
}

.list-group-item-header > h1 {
    font-size: 125%;
    text-align: left;
}

.modal-body > textarea {
    resize: none;
    padding: 1%;
    width: 100%;
    height: 15vh;
}

div.modal-footer {
    margin: none;
    padding: none;
    align-items: center;
    justify-content: center;
}

div.modal-footer > button {
    width: 100%;
}

.modal-body > h1 {
    font-size: 125%;
    text-align: left;
}

.modal-body {
    cursor: default;
}

.list-group-item:hover:not(.list-group-item-header) {
    background-color: #f5f5f5;
}

.font-awesome-button {
    padding: 1.5%;
    border-radius: 50%;
    background-color: black;
    color: white;
}

.font-awesome-button:hover {
    background-color: gray;
    color: black;
    cursor: pointer;
}

.modal-header > div {
    cursor: default !important;
}

.hidden-element {
    visibility: hidden;
    opacity: 0;
}
