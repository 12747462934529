$bg-white : white;
$bg-lightgrey : #eee; // or rgb(245, 245, 245)
$max : 100%;
$small-mobile-width : 450px;
$max-mobile-width : 768px;
$min-desktop-width : 1024px;
$medium-desktop-width : 1440px;
$large-desktop-width : 1624px;
$font-color-default : #000;
$font-color-grey : #999;
$font-color-light : #fff;
$font-family-Roboto : 'Roboto', sans-serif;
$button-color-default : #10bf59;
$button-color-alt : #0f9e4b;
$button-size : 8em;
$highlight-color-lightgray : rgb(211, 211, 211);
$icon-color-grey : rgb(89, 89, 89);
$margin-leftRight: 2.5%;
$margin-leftRight-768: 0.5rem;
$paragraph-margin-bottom: 1.25rem;
$padding-small : 0.5rem;
$font-size-default : 1rem;
$font-size-desktop : 1.5rem;
$h1-font-size: 200%;
$h4-font-size: 150%;
$p-font-size: 115%;
$default-border-radius : 0.5rem;
$default-box-shadow :rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
