.notFoundContainer {
    font-family: 'Inter', sans-serif;
    margin-bottom: 100px;
    height: 60vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.notFoundBanner {
    height: 110%;
    width: 560px;
    background: url(../imgs/notFound.jpg);
    background-size: cover;
    background-position: center;
    margin-bottom: 10px;
    z-index: 0;
    border-radius: 5px;
}
h2 {
    font-size: 1.4rem;
}


.homeBtn {
    background-color: #10bf59;
    border: 1px solid #13aa52;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    outline: 0;
    padding: 5px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    cursor: pointer;
    margin-top: 15px;
}

