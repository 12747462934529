/* global list of common mixins, used in multiple components, designed to limit redundancy */

@mixin setSize($width, $height) {
    width: $width;
    height: $height;
}
  
@mixin setMaxSize($max-width, $max-height) {
    max-width: $max-width;
    max-height: $max-height;
}

@mixin setMarginHorizontal ($margin-left, $margin-right) {
    margin-left: $margin-left;
    margin-right: $margin-right;
}
  
@mixin setMarginVertical($marginTop, $marginBottom) {
    margin-top: $marginTop;
    margin-bottom: $marginBottom;
}

@mixin flexAlign($justify, $align) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}
  
@mixin flexDirection($direction) {
    display: flex;
    flex-direction: $direction;
}

//only limited to 2x2 grids, may be redundant as it's not that flexible
@mixin setGridColumns ($column1, $column2){
    display: grid;
    grid-template-columns: $column1 $column2;
}

//only limited to 2x2 grids, may be redundant as it's not that flexible
@mixin setGridRows ($row1, $row2){
    display: grid;
    grid-template-rows: $row1 $row2;
}


@mixin placeItemOnGrid($row, $column) {
    grid-row: $row;
    grid-column: $column;
}

