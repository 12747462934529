@import './adminPanelVariables';
@import '../globalmixins';

@mixin buttonStyle {
  width: $button-size;
  padding: $padding-small;
  font-size: $font-size-default;
  color: $font-color-light;
  background: $button-color-default;
  border-radius: $default-border-radius;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &:hover {
      background: $button-color-alt;
      color: $font-color-light;
  }

  &:active {
    box-shadow: none;
    color: $font-color-light;
    background-color: $button-color-default;
  }

  &:focus  {
    box-shadow: none;
  }

}

.selectedUserContainerAdmin {
  background-color: $bg-lightgrey;
}

.communityModalBtn {
  @include flexAlign(center, center);
  color: white;
  font-size: 100%;
  border-color: $button-color-default;
  background: $button-color-default;
  width: 100%;

  &#deleteCommunityBtn {
      border-color: red;
      background: red;
      &:hover {
          background: darkred;
      }
  }
  
  &:hover {
      background: $button-color-alt;
      border-color: $button-color-alt;
  }
}

.adminContainer {
  @include setSize($max, $max);
  @include flexDirection(column);
  background-color: #eee;
  color: black;
  font-size: 1.2rem;
  overflow: hidden;

  nav {
    @include setSize(100vw, 4.5rem);
    @include flexAlign(center, center);
    @include flexDirection(row);
    // margin: auto $margin-leftRight;
    background-color: white;
    box-sizing: border-box;
    overflow: hidden;
    font-weight: bold;
    @media screen and (max-width: 768px) {
      width: auto;
    }

    li {
      @include setSize($max, $max);
      @include flexAlign(center, center);
      font-size: 1.5rem;
      box-sizing: border-box;
      transition: border-bottom-color 0.3s;
      margin-top: 2px;
      border-bottom: 5px solid transparent;
      text-align: center;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
      }

      @media screen and (max-width: 480px) {
        font-size: 0.8rem;
      }

      &:hover {
        cursor: pointer;
        border-bottom: 5px solid lime;
      }

      &.active {
        border-bottom: 5px solid $button-color-alt;
      }

    }
  }

  .messageContainerAdmin {
    height: 81.5vh;
  }

}

/* Home page for admin */

.aboutPanel {
  @include setSize($max, $max);
  @include flexDirection(column);
  // @include flexAlign(flex-start, flex-start);
  align-self: center;
  background: white;
  margin: 1.5rem $margin-leftRight;
  padding: 2rem;
  box-sizing: border-box;
  overflow: auto;
  max-width: 1200px;
  box-shadow: $default-box-shadow;
  border-radius: $default-border-radius;

  h1 {
    font-size: $h1-font-size;
    font-weight: bold;
    margin-bottom: $paragraph-margin-bottom;
  }

  h4 {
    font-size: $h4-font-size;
    font-weight: bold;
    margin-bottom: $paragraph-margin-bottom;
  }

  p {
    font-size: $p-font-size;
    margin-bottom: $paragraph-margin-bottom;
  }

  li {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

/* Communities page for admin */

.communityRequestContents {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 5%;
}

.communityAdminContents {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .manageCommunityList {
    grid-column: 1 / span 2;
    overflow-y: scroll;
    height: 14rem;
    width: 100%;
  }
  
  .manageCommunityListItem {
    @include flexAlign(space-between, center);
    @include setMarginVertical(0.5rem, 0.5rem);
    padding: 0.5rem;
    background: white;
    border-radius: 1rem;
  
    &:hover {
      background: $bg-lightgrey;
    }

    .manageContainer {
      @include flexDirection(row);
      gap: 1rem;

      .checkMark,
      .xMark {
        font-size: 150%;
        &:hover {
          cursor: pointer;
          filter: brightness(255);
        }
      }
    }
  
  }

  button {
    width: 70%;
  }
}

.manageCommunityList {
  column-span: 2;
}


/* User page for admin */

.noUserSelected {
  @include flexAlign(center, center);
}

.userDetailsContainer {
  height: 85vh;
  overflow-y: scroll;

  header {
    position: absolute;
    z-index: 10;
  }
  
  section {
    @include flexDirection(column);
    background: $bg-white;
    margin:  $margin-leftRight;
    box-sizing: border-box;
    border-radius: $default-border-radius;
    box-shadow: $default-box-shadow;

    h1 {
      padding-top: 1rem;
    }

    h2 {
      text-align: center;
      padding-top: 1rem;
    }

  }

   button {
    @include setMarginVertical(1rem, 1rem);
  }

  .communityType {
    @include flexDirection(row);
    margin: 0;
    padding: 1rem;
    width: 100%;
  }

  .roleSelect {
    margin-top: 1rem;
    @include flexDirection(row);
    @include setMarginHorizontal(auto, auto);
    width: 80%;

    h2 {
      width: 50%;
    }

    h4 {
      @include flexAlign(center, center);
      margin-bottom: 0;
    }
    
  }

  .userInfoSection {
    margin-top: 3rem;
  }

  .otherUserInfo  {
    @include setMarginHorizontal(0.5rem, 0);
    p {
      text-align: left;
    }
    
  }

  .userCommunitiesSection {
    @include flexDirection(column);
    min-height: 100%;

    h1 {
      font-size: $h4-font-size;
      padding: 0;
    }

    header {
      @include flexDirection(column);
      position: unset;
      z-index: 0;

    }

    .search-bar {
      width: 90%;
      height: 3rem;
    }

    .messageListItems {
      border-radius: 0 0 $default-border-radius $default-border-radius;
      height: 50%;
    }



  }

  @media screen and (min-width: $min-desktop-width) {
    @include setGridColumns(1fr, 1fr);
    @include setGridRows(1fr, 1fr);
    height: 81.5vh;
    overflow: hidden;

  
    .userInfoSection {
      @include placeItemOnGrid(1, 1);
      margin-top: $margin-leftRight;
    }
    
    .userUpdateSection {
      @include placeItemOnGrid(2, 1);
    }
  
    .userCommunitiesSection {
      @include placeItemOnGrid(1 / span 2, 2);
      min-height: 0;

      .messageListItems {
        height: 100%;
      }

      .messageListItem {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
  
        h4 {
          margin-left: 1.5rem;
        }
      }
    }

  }
}

.adminBtn  {
  @include buttonStyle;
  @include setMarginHorizontal(auto, auto);
}

