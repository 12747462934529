
.aboutBannerContainer {
    height: 70vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../imgs/aboutpagebanner.jpg);
    background-size: cover;
    background-position: center;
    z-index: 0;
}
.AboutUsContainer {
    margin-top: auto;
    background-color: #fff;
    position: relative;
    z-index: 1;
}

.aboutLogo {
    width: 400px;
    height: 300px;
    margin-bottom: 40px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    z-index: 1;
}
.logoImage {
    width: 400px;
    height: 300px;
}

.aboutUsTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 60%;
    margin-right: auto;
    margin-left: auto;
    color: black;
    padding: 20px;
    height: 60vh;
}

.aboutUsTextContainer > div {
    padding: 20;
    
}

.aboutTittle {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
}

.aboutDetails {
    background-color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}

.moreAboutContentContainer {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 60%;
    margin-right: auto;
    margin-left: auto;

}

.moreaboutText {
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}


.joinContentContainer {
    height: 35vh;
    padding: 20px;
    width: 100%;
    font-family: 'Quicksand', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
}

.registerButton {
    background-color: #209d50;
    border: 1px solid #209d50;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    outline: 0;
    padding: 10px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    z-index: 0;
    margin: 10px;
    width: 200px;
  }