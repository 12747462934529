@import url("https://fonts.googleapis.com/css2?family=Staatliches:wght@400&display=swap");
$secondary-color: #001841;

@import url("https://fonts.googleapis.com/css2?family=dm-sans:wght@400&display=swap");
$secondary-color: #001841;

.comingsoonContainer {
    padding-bottom: 10rem;
}

.Staatliches {
    font-family: "Staatliches", sans-serif;
}

.dm-sans {
    font-family: "dm-sans", sans-serif;
}

/* sign up stuff */
#puzzleImage {
    width: 18rem;
    height: 18rem;
    object-fit: cover;
}

#comingsoonSignup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 64px;
        padding-bottom: 1rem;
        letter-spacing: 0;
    }

    h4 {
        text-align: center;

        padding: 0 10%;
        width: 60%;
        letter-spacing: 0;
        font-weight: 350;
    }
}

.signup-div {
    width: 100%;
    
    form {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 2rem;
        align-items: center;
        justify-content: center;
    }   

    p {
        display: flex;
        justify-content: center;
    }
}

#signup1Input {
    border: 1px #959595 solid;
    width: 22rem;
    height: 3rem;
    align-self: center;
    border-radius: 0.5rem;
    padding: 0.55rem;

    &:hover {
        border: 1px rgb(0, 0, 0) solid;
    }

    &::placeholder {
        color: #888888;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

}

#whatWeOffer {
    font-size: 64px;
    margin-bottom: 32px;
}

.imageSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    #mainImage {
        width: 100%;
        height: 700px;
        justify-self: center;
        align-self: center;
    }
}


.signup1Button {
    width: 10rem;
    height: 3rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: rgb(54, 118, 255);
    color: white;
    margin-left: 0.5rem;
    margin-right: 1rem;

    &:hover {
        background: $secondary-color;
    }
}

#error1{
    display: flex;
    justify-content: center;
    color: black;
    font-size: 0.8rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}



/* main stuff */
.comingsoonMain {
    margin-top: 5rem;
    display: grid;
    grid-template-rows: 10% 45% 45%;
    grid-template-columns: 40% 40%;
    padding: 0 15%;
    column-gap: 20%;
    font-family: "dm-sans", sans-serif;

    .mainHeader {
        padding: 0 10%;
        margin-bottom: 120px;
        justify-self: center;
        align-self: center;
        grid-column: span 2;
    }

    .mainHeader h3 {
        margin-bottom: 0;
    }

    .mainHeader h4 {
        text-align: center;
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 7rem;
            height: 7rem;
        }

        p {
            text-align: center;
            margin: 0;
            
        }
    }
}

div.mainDiv.left {
    place-self: center;
    margin-bottom: 2rem;
}

div.mainDiv.right {
    place-self: center;
    margin-bottom: 2rem;
}

/* info stuff */

.comingsoonInfo {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 55% 35%;
    padding: 0 15%;
    margin-top: 7rem;
    margin-bottom: 7rem;
    gap: 10%;
    
}

.infoDiv {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    padding: 0 10%;

    h2 {
        font-weight: bold;
        margin-bottom: 2rem;
    }
}

#kilroy {
    grid-column: 2;
    width: 18rem;
    height: 12rem;
    object-fit: cover;
}

/* footer stuff */
.comingsoonFooter {
    padding: 7rem 0;
    padding-left: 20%;
    padding-right: 50%;

    background: $secondary-color;

    h1, h2, h3, h4, p {
        color: white;
    }

    h4 {
        font-size: 1.2rem;
    }
}

#footerTitle {
    border-bottom: 2px solid white;
    margin-bottom: 1rem;
}

.comingsoonFooterContainer {
    width: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
}

#error2 {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 0.8rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.fullStoryButton {
    margin-top: 1rem;
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    text-align: left;
}


@media screen and (max-width: 1000px) {

    #comingsoonSignup {
        h4 {
            padding: 0;
            margin: 0;
        }
    }

    #mainImage {
        width: 100%; /* Set the image width to 100% to cover the entire screen width */
        max-height: 700px; /* Set a maximum height of 700px */
        object-fit: cover; /* Maintain aspect ratio and cover the entire container */
    }

    .comingsoonMain {
        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        padding: 0 0;
        gap: 2rem;

        h3 {
            padding: 0;
            margin: 0;
        }
    }

    .comingsoonMain .mainHeader {
        margin-bottom: 2rem;
    }

    .signup-div {
        width: 100%;
    }

    #signup1InputContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #signup1Input {
        width: 50%;

    }
    
    .signup1Button {
        margin-top: 1rem;
        width: 50%;
        margin-left: 0;
        margin-right: 0;
    }

    .comingsoonInfo {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 20% 75%;
    }

    .infoDiv {
        grid-column: 1;
        grid-row: 2;
        margin-bottom: 20rem;
    }

    #kilroyContainer {
        display: flex;
        justify-content: center;
    }

    #kilroy {
        grid-column: 1;
        grid-row: 1;
    }

    .comingsoonFooter {
        padding: 7rem 0;
        padding-left: 25%;
        padding-right: 25%;
    }
    
    .comingsoonFooterContainer {
        width: 100%;
    }

}

@media screen and (max-width: 650px) {

    #comingsoonSignup h1 {
        font-size: 3rem;
    }

    #whatWeOffer {
        font-size: 2.5rem;
    }

    .mainHeader h4 {
        font-size: 1.25rem;
    }


    #comingsoonSignup h4 {
        font-size: 1.25rem;
    }

    .comingsoonMain .mainHeader {
        font-size: 1.3rem;
    }

    #signupThanks h4 {
        font-size: 1.25rem;
    }

    #signup1Input {
        width: 80%;
    }
    
    .signup1Button {
        width: 80%;
    }

}