$background-default : #fff;
$max : 100%;
$small-mobile-width : 450px;
$max-mobile-width : 768px;
$min-desktop-width : 1024px;
$medium-desktop-width : 1440px;
$padding-default : 1rem;
$padding-small : 0.5rem;
$font-size-xsmall : 0.6rem;
$font-size-small : 0.8rem;
$font-size-default : 1rem;
$font-size-desktop : 1.2rem;
$font-color-default : #000;
$font-color-grey : #999;
$font-color-light : #fff;
$font-family-Roboto : 'Roboto', sans-serif;
$button-color-default : #10bf59;
$button-color-alt : #0f9e4b;
$highlight-color-lightgray : rgb(211, 211, 211);
$icon-color-grey : rgb(89, 89, 89);
$default-border-radius : 0.5rem;
$large-border-radius : 1rem;
$default-box-shadow :rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;


