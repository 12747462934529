body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: rgb(237, 237, 237);
    z-index: -4;
    display: flex;
    flex-direction: column;
}

html {
    background-color: rgb(51, 51, 51);
}

.AppBody {
    margin: 0;
    padding: 0;
    flex: 1 0 auto; 
}
.router-Container {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: rgb(237, 237, 237);
}

a:link {
    text-decoration: none!important;
}
